<template>
  <!-- eslint-disable max-len -->
  <div class="rk_owl_logo">
    <div class="rk_owl_logo_intro">

    </div>
    <svg version="1.1" id="owl_logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 481.89 168.43" style="enable-background:new 0 0 481.89 168.43;" xml:space="preserve">

    <g>
      <g class="rk_owl_and_f">
        <path class="st0" d="M198.85,55.89c0,4.87-0.8,9.39-2.41,13.54c-1.61,4.16-3.89,7.75-6.83,10.78c-2.94,3.04-6.48,5.4-10.62,7.1
        c-4.14,1.7-8.74,2.56-13.8,2.56c-5.03,0-9.61-0.85-13.75-2.56c-4.14-1.7-7.69-4.07-10.65-7.1c-2.96-3.04-5.25-6.63-6.88-10.78
        c-1.63-4.16-2.44-8.67-2.44-13.54c0-4.88,0.81-9.39,2.44-13.55c1.63-4.15,3.92-7.75,6.88-10.78c2.96-3.04,6.51-5.4,10.65-7.1
        c4.14-1.7,8.72-2.55,13.75-2.55c3.37,0,6.55,0.39,9.52,1.17c2.97,0.78,5.7,1.88,8.19,3.31c2.48,1.42,4.71,3.16,6.69,5.2
        s3.66,4.31,5.03,6.83c1.38,2.52,2.43,5.24,3.15,8.19C198.49,49.55,198.85,52.64,198.85,55.89z M186.2,55.89
        c0-3.65-0.49-6.92-1.47-9.82c-0.98-2.9-2.38-5.36-4.18-7.38c-1.81-2.02-4.01-3.57-6.6-4.64c-2.59-1.07-5.51-1.61-8.76-1.61
        s-6.17,0.54-8.76,1.61c-2.59,1.07-4.8,2.62-6.62,4.64c-1.82,2.02-3.23,4.48-4.21,7.38c-0.98,2.9-1.47,6.17-1.47,9.82
        c0,3.65,0.49,6.92,1.47,9.82c0.98,2.9,2.38,5.35,4.21,7.36s4.03,3.55,6.62,4.62c2.59,1.08,5.51,1.61,8.76,1.61
        s6.17-0.54,8.76-1.61c2.59-1.07,4.79-2.61,6.6-4.62c1.81-2.01,3.2-4.46,4.18-7.36C185.72,62.81,186.2,59.54,186.2,55.89z"/>
        <path class="st0" d="M296.86,22.64l-20.65,66.5h-11.18L250.5,43.7c-0.4-1.1-0.77-2.45-1.11-4.05c-0.18,0.77-0.37,1.49-0.55,2.16
        c-0.18,0.68-0.39,1.3-0.6,1.88l-14.67,45.44h-11.22l-20.6-66.5h10.35c1.07,0,1.97,0.25,2.69,0.76c0.72,0.51,1.2,1.19,1.45,2.05
        l11.32,39.78c0.24,0.98,0.48,2.05,0.71,3.2c0.23,1.15,0.47,2.35,0.71,3.61c0.25-1.29,0.51-2.5,0.8-3.63
        c0.29-1.13,0.61-2.19,0.94-3.17l13.06-39.78c0.25-0.7,0.73-1.35,1.45-1.93c0.72-0.58,1.6-0.87,2.64-0.87h3.63
        c1.07,0,1.95,0.26,2.62,0.78c0.67,0.52,1.18,1.2,1.52,2.02l13.02,39.78c0.65,1.9,1.23,4.09,1.75,6.58
        c0.4-2.42,0.84-4.61,1.33-6.58l11.31-39.78c0.18-0.77,0.65-1.43,1.4-1.98c0.75-0.55,1.65-0.83,2.69-0.83H296.86z"/>
        <path class="st0" d="M344,78.93v10.21h-38.95v-66.5h12.37v56.29H344z"/>
        <path class="st1" d="M138.32,146.63h-5.6v-36.2h25.19v5.09h-19.6v10.1h15.38v5.09h-15.38V146.63z"/>
        <path class="st1" d="M179.94,109.89c11.01,0,19.17,7.89,19.17,18.82c0,10.85-8.16,18.45-19.17,18.45
        c-10.82,0-18.98-7.6-18.98-18.45C160.96,117.79,169.12,109.89,179.94,109.89z M179.94,141.86c7.97,0,13.3-5.31,13.3-13.2
        c0-7.86-5.33-13.46-13.3-13.46c-7.76,0-13.06,5.6-13.06,13.46C166.88,136.55,172.18,141.86,179.94,141.86z"/>
        <path class="st1" d="M221.81,135.54h-9.97v11.09h-5.6v-36.2h16.39c6.8,0,12.64,3.97,12.64,12.64c0,5.78-3.2,9.84-7.63,11.57
        l9.92,11.97h-6.85L221.81,135.54z M211.84,115.52v14.98h9.76c4.32,0,7.84-2.8,7.84-7.38c0-5.07-3.52-7.6-7.84-7.6H211.84z"/>
        <path class="st1" d="M262.64,134.66l-13.46-14.9v26.87h-5.6v-36.2h5.04l14.64,17.22l14.55-17.22h5.01v36.2h-5.6v-26.93
        l-13.36,14.95H262.64z"/>
        <path class="st1" d="M315.09,139.09h-18.26l-2.93,7.55h-5.97l14.93-36.2h6.13l14.96,36.2h-5.97L315.09,139.09z M298.37,134.42
        h15.03l-7.46-17.73L298.37,134.42z"/>
        <path class="st1" d="M333.15,115.68h-11.97v-5.25h29.46v5.25h-11.92v30.95h-5.57V115.68z"/>
        <path class="st1" d="M361.73,146.63h-5.6v-36.2h5.6V146.63z"/>
        <path class="st1" d="M387.84,109.89c11.01,0,19.17,7.89,19.17,18.82c0,10.85-8.16,18.45-19.17,18.45
        c-10.82,0-18.98-7.6-18.98-18.45C368.86,117.79,377.01,109.89,387.84,109.89z M387.84,141.86c7.97,0,13.3-5.31,13.3-13.2
        c0-7.86-5.33-13.46-13.3-13.46c-7.76,0-13.06,5.6-13.06,13.46C374.77,136.55,380.08,141.86,387.84,141.86z"/>
        <path class="st1" d="M419.73,146.63h-5.6v-36.2h5.6l23.11,27.59v-27.59h5.6v36.2h-5.65l-23.06-27.33V146.63z"/>
        <path class="st1" d="M480.16,111.87l-0.48,5.31c-2.48-1.39-6.43-2.19-9.86-2.19c-4.75,0-7.84,1.63-7.84,5.14
        c0,3.79,2.96,4.35,5.84,5.2l5.3,1.57c5.33,1.57,8.77,4,8.77,9.36c0,6.24-5.31,10.9-13.04,10.9c-4.91,0-10.51-1.07-13.12-2.5
        l0.48-5.15c2.64,1.25,7.94,2.5,12,2.5c4.35,0,7.65-1.87,7.65-5.33c0-2.91-1.55-4.08-5.07-5.07l-5.81-1.63
        c-4.29-1.23-9.01-3.55-9.01-9.15c0-6.61,4.83-10.96,13.17-10.96C474.03,109.89,477.33,110.77,480.16,111.87z"/>
      </g>
      <g class="rk_owl">

        <path id="sidebar-wave"
        d="M270 202.14L270 520.13L0 202.14L270 202.14ZM270 520.13L0 202.14L1.38 521.23L270 520.13ZM270 -0.72L0 67.14L270 202.14L270 -0.72ZM0 202.14L270 202.14L0 67.14L0 202.14ZM79.65 2.66L0 0L0 67.14L150.22 67.94L270 67.14L270 0L194.69 2.94L79.65 2.66Z"
        data-to="M270 520.13L0 250.13L270 250.13L270 520.13ZM49.85 367.96L49.85 473.45L155.34 473.45L49.85 367.96ZM202.86 202.14L270 202.14L270 135L202.86 202.14ZM67.14 202.14L0 135L0 202.14L67.14 202.14ZM0 0L0 67.14L135 202.14L270 67.14L270 0L202.86 67.14L67.14 67.14L0 0Z"/>
      </g>
    </g>
  </svg>
</div>
</template>

<script>
import dynamics from 'dynamics.js';

export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  mounted() {
    const path = document.querySelector('#sidebar-wave');
    const rect = path.getBoundingClientRect();
    const logoIntro = document.querySelector('.rk_owl_logo_intro');
    logoIntro.setAttribute('style', `top:${rect.top}px;left:${rect.left}px;height:${rect.height}px;width:${rect.width}px;position:fixed;`);

    // const from = path.getAttribute('d');
    const to = path.getAttribute('data-to');
    const options = {
      type: dynamics.easeOut,
      duration: 1500,
      friction: 450,
      delay: 500,
    };
    dynamics.animate(path, {
      d: to,
    }, options);
    setTimeout(() => {
      logoIntro.remove();
    }, 500);
  },
};
</script>

<style scoped lang="scss">
.st0,.st1{fill:transparent;}
.st2{fill:#1A9B69;}
#sidebar-wave {
  fill:#1A9B69;
  transform: scale(0.3) translatex(12%);
}
$green: white;

.rk_owl_logo_intro:after{
  content:'';
  position: absolute;
  left:0;
  top:0;
  display: block;
  background: #1A9B69;
  z-index: 10;
  animation: openingOwl 0.5s ease;
}

@keyframes loadingReady{
  0%{
    width:80%;
    height:100vh;
  }

  100%{
    width:60%;
    height:40vh;
  }
}
@keyframes appearingOwl{
  0%{
    opacity:0;
  }

  100%{
    opacity:1;
  }
}
@keyframes openingOwl{
  0%{
    height: 0px;
    width:5px;
  }
  50%{
    height: 100%;
    width:5px;
  }

  100%{
    height: 100%;
    width:100%;

  }
}
@keyframes menu{
  0%{opacity: 0;
    stroke:$green;
    fill:none;
    stroke-dashoffset: 600;}
    10%{
      opacity: 10;
      fill:none;
      stroke-dashoffset: 600;
    }
    80%{
      fill:rgba(0,0,0,0);
      stroke-dashoffset: 0;
    }
    100%{
      opacity: 10;
      stroke:$green;
      fill: $green;
      stroke-dashoffset: 0;

    }
  }

  #owl_logo{
    position: relative;
    width:80%;
    height: 100vh;
    padding:50px 0;
    animation: loadingReady 0.4s ease-in 2.4s;
    animation-fill-mode: forwards;
    .rk_owl{
      opacity: 0 ;
      animation: appearingOwl 0s ease 0.5s;
      animation-fill-mode: forwards;
    }
    .rk_owl_and_f{
      .st0{
        stroke:$green;
        fill: $green;
        stroke-dasharray: 600;
        opacity: 1;
        animation: menu 2s cubic-bezier(0,0.23,1,.1);
        animation-delay: 0s;
      }

      .st1{
        opacity: 1;
        stroke-dasharray: 600;
        animation: menu 2s cubic-bezier(0,0.23,1,.1);
        animation-fill-mode: forwards;
        animation-delay: 0s;
      }
    }
  }

  </style>
