<template>
  <div class="home" :class="this.$store.getters.getState">
    <div  class="rk_home_title">

      <Logo>
      </Logo>
      <!-- <div class="rk_owl_btn" @click='morphToBall'>
        <svg xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1" preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 125 24" width="125" height="24"><defs>
          <path d="M124.96 0L124.69 23.92L0.04 24L0.31 0.01L124.96 0Z"
          id="bOh7PhVhB"/>
        </defs><g><g><use xlink:href="#bOh7PhVhB" opacity="1"
          fill="#1a9b69" fill-opacity="1"/><g><use xlink:href="#bOh7PhVhB"
            opacity="1" fill-opacity="0" stroke="#000000"
            stroke-width="0.78" stroke-opacity="0"/>
          </g>
        </g>
      </g>
    </svg>
    <p>owl btn</p> -->
  <!-- </div> -->
  <h1>
    <p>Owl formations</p>
    <!-- <p>Formations du digital en savoie et haute-savoie</p> -->
  </h1>
  <h2>On arrive bientôt ! </h2>
  <p>En attendant, vous pouvez nous contacter ici :
    <a href="mailto:bonjour@owlf.school">bonjour@owlf.school</a>
  </p>
</div>

<!-- <Pages v-for="nb in nbPage" :key="nb" :totalPages="nbPage"
:index="nb" :currentPage="currentPage" :pageTitle="'titre '+ nb"/> -->

</div>
</template>

<script>
// @ is an alias to /src
// import Pages from '@/components/pages.vue';
import Logo from '@/components/logo.vue';

export default {
  name: 'Home',
  data() {
    return {
      nbPage: 4,
      speed: 0,
      movingInterval: '',
      logoHtml: '',
      currentPage: this.$store.getters.getCurrentPage,
    };
  },
  watch: {
    $route() {
      clearInterval(this.movingInterval);
    },
    speed(val, oldVal) {
      if (val !== oldVal) {
        clearInterval(this.movingInterval);
        const vue = this;

        if (vue.currentPage + vue.speed <= 0 && vue.speed < 0) {
          vue.currentPage = 1;
        } else if (vue.currentPage + vue.speed > vue.nbPage && vue.speed > 0) {
          vue.currentPage = vue.nbPage;
        } else {
          vue.currentPage += vue.speed;
        }
        let pageElem = `.page-${vue.currentPage}`;
        let nextElem = document.querySelector(pageElem);
        nextElem.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
          inline: 'center',
        });

        this.movingInterval = setInterval(() => {
          if (vue.currentPage + vue.speed <= 0 && vue.speed < 0) {
            vue.currentPage = 1;
          } else if (vue.currentPage + vue.speed > vue.nbPage && vue.speed > 0) {
            vue.currentPage = vue.nbPage;
          } else {
            vue.currentPage += vue.speed;
          }
          pageElem = `.page-${vue.currentPage}`;
          nextElem = document.querySelector(pageElem);
          nextElem.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
            inline: 'center',
          });
        }, 500);
      } else {
        clearInterval(this.movingInterval);
      }
    },
  },
  components: {
    // Pages,
    Logo,
  },
  methods: {
    morphToBall: () => {
      console.log('ok');
    },
  },
  /*  mounted() {
  if (this.$store.getters.getState.length === 0) {
  this.$store.commit('appState', 'mounted');
}
const vue = this;
const application = document.querySelector('#app');
application.style.width = `${this.nbPage * 45 + 30}%`;
const handleMousemove = (event) => {
const position = event.x;
const percent = ((Math.round(100 - (((position * 100) / window.innerWidth))) * 2) - 100) * -1;
if ((percent <= -90)) {
vue.speed = -1;
} else if (percent > -90 && percent <= 90) {
vue.speed = 0;
} else if (percent > 90) {
vue.speed = 1;
}
};
document.addEventListener('mousemove', handleMousemove);
setTimeout(() => {
this.$store.commit('appState', 'loaded');
}, 4000);
}, */
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

$pages: 1, 2, 3, 4, 5, 6;
$green: #1A9C69;
// .rk_owl_btn{
//   position:relative;
//   display:inline-block;
//   z-index: 1;
//   width:200px;
//   height:30px;
//   padding: 10px 0;
//   background-color:$green;
//   &:hover:after{
//     transform: translate(20px, 20px);
//   }
//   &:after{
//     content: '';
//     width: 0;
//     height: 0;
//     right:0;
//     top:0;
//     position:absolute;
//     z-index: 0;
//     transition:all 0.4s;
//     border-left: 200px solid transparent;
//     border-right: 0px solid transparent;
//     border-bottom: 40px solid transparent;
//   }
//   &:before{
//     content: '';
//     width: 0;
//     height: 0;
//     left:0;
//     top:0;
//     position:absolute;
//     z-index: 0;
//     transition:all 0.4s;
//     border-right: 200px solid transparent;
//     border-left: 0px solid transparent;
//     border-top: 40px solid $green;
//   }
//   p{
//     margin:0;
//     position:relative;
//     z-index: 1;
//     font-size: 30px;
//     color:#15161a;
//     line-height: 30px;
//     text-transform: uppercase;
//     font-family: 'Lato', sans-serif;
//   }
// }
.home{
  a{
    color: white;
  }
  @each $index in $pages {
    &.mounted{
      .page-#{$index} {
        animation: positioning-#{$index} 2s ease calc(calc(6 - $index) * 0.4s) ;
        animation-fill-mode: forwards;
        border: 10px solid;
        border-image-slice: 1;
        border-width: 5px;
        border-image-source: linear-gradient(to right, white 50%, transparent 50%);

      }
      @keyframes positioning-#{$index} {
        25%  {
          transform: translatex(0%);
          width:100px;
          height:50px;
          top: calc(100% - 150px);
          left:20%;}
          50% {
            transform: translatex(0%);
            width:100px;
            height:50px;
            top: calc(100% - 150px);
            left:calc($index * 33%);
          }
          75% {
            transform: translatex(0%);
            width:100px;
            height:60%;
            top: 20%;
            left:calc($index * 33%);
          }
          100% {
            transform: translatex(-50%);
            width:40%;
            height:60%;
            top: 20%;
            left:calc($index * 45%);
          }
        }
      }
      &.loaded{
        .page-#{$index} {
          transform: translatex(-50%);
          width:40%;
          height:60%;
          top: 20% !important;
          left:calc($index * 45%);
          border: 10px solid;
          border-image-slice: 1;
          border-width: 5px;
          border-image-source: linear-gradient(to right, white 50%, transparent 50%);
          transition: all 0.4s;
        }
      }
      .rk_page_card{
        left:0;
      }
    }

  }

  </style>
