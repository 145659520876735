import { createStore } from 'vuex';

export default createStore({
  state: {
    appState: '',
    currentPage: 1,
  },
  mutations: {
    appState(state, newState) {
      state.appState = newState;
    },
    currentPage(state, newCurrentPage) {
      state.currentPage = newCurrentPage;
    },
  },
  getters: {
    getState: (state) => state.appState,
    getCurrentPage: (state) => state.currentPage,

  },
  actions: {
  },
  modules: {
  },
});
